import * as React from 'react';
const classnames = require('classnames');

import './Slant.scss';

/**
 * Based on `frogo-slant.svg`
 * @param props
 */
const Slant = (props: React.SVGProps<SVGSVGElement>) => {
  const classes = classnames('Slant', props.className, 'd-ie-none');
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 1920 122"
      overflow="visible"
      enableBackground="new 0 0 1920 122"
      xmlSpace="preserve"
      {...props}
      className={classes}
    >
      <polygon fill="currentColor" points="1920,0 0,122.4 1920,122.4 " />
    </svg>
  );
};

export default Slant;
