import * as React from 'react';
import Helmet from 'react-helmet';

import '../assets/styles/scss/frogo.scss';

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  require('smooth-scroll')('a[href*="#"]', {
    offset: 30
  });
}

class Layout extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <div>
        <Helmet
          defaultTitle={`FROGO AD – Context-Driven-Targeting`}
          titleTemplate={`%s | FROGO AD`}
        >
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="FROGO AD" />
          <meta name="frogo:commit" content={`${process.env.GATSBY_COMMIT}`} />
          <meta
            name="frogo:build_number"
            content={`${process.env.GATSBY_BUILD_NUMBER}`}
          />
          <html lang="de" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`/favicon-16x16.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`/favicon-32x32.png`}
          />
        </Helmet>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default Layout;
