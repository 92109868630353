import * as React from 'react';
import { Link } from 'gatsby';
import { Nav, NavItem } from 'reactstrap';

import './Footer.scss';

interface FooterProps {
  pullToBottom?: boolean;
}

const Footer = (props: FooterProps) => {
  const footerStyle: React.CSSProperties = props.pullToBottom
    ? { position: 'absolute', bottom: '0px', width: '100%' }
    : {};

  return (
    <footer className="Footer pt-2 pb-2" style={footerStyle}>
      <Nav className="align-items-center justify-content-center small">
        <NavItem>
          <Link className="nav-link Footer-nav-link" to="/impressum">
            Impressum
          </Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link Footer-nav-link" to="/datenschutz">
            Datenschutz
          </Link>
        </NavItem>
        <NavItem>
          <span style={{ color: '#8f979d' }} className="pl-3 pr-3">
            &copy; {new Date().getFullYear()} FROGO AD
          </span>
        </NavItem>
      </Nav>
    </footer>
  );
};

export default Footer;
