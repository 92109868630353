import * as React from 'react';
const raf = require('raf');
const classnames = require('classnames');

import './SwappingNav.scss';

interface SwappingNavProps {
  scrollInHeight: number;
  initialNavbar: React.ReactElement<any>;
  swappedNavbar: React.ReactElement<any>;
}

interface SwappingNavState {
  swapped: boolean;
}

/**
 * Swaps between two different nav bars based on scroll offset. Navbars are hidden by moving them above the viewport.
 * Only Navbars with `fixed-top` position are supported.
 *
 * Inspired by https://slack.com/developers.
 *
 * Implementation based on `ScrollInNav`:
 * - https://stackoverflow.com/questions/38114715/how-to-reveal-a-react-component-on-scroll
 * - https://gist.github.com/brthornbury/27531e4616b68131e512fc622a61baba
 * - https://github.com/KyleAMathews/react-headroom
 */
export default class SwappingNav extends React.Component<
  SwappingNavProps,
  SwappingNavState
> {
  isHandlingScrollUpdate = false;
  lastKnownScrollY = 0;

  constructor(props: SwappingNavProps) {
    super(props);

    this.state = {
      swapped: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (!this.isHandlingScrollUpdate) {
      this.isHandlingScrollUpdate = true;
      raf(this.update);
    }
  };

  update = () => {
    const currentScrollY = this.getScrollY();

    if (this.shouldUpdate(currentScrollY, this.lastKnownScrollY)) {
      this.setState({
        swapped: currentScrollY > this.props.scrollInHeight
      });
    }

    this.isHandlingScrollUpdate = false;
    this.lastKnownScrollY = currentScrollY;
  };

  getScrollY = () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/pageYOffset
    return window.pageYOffset;
  };

  shouldUpdate = (currentScrollY: number, lastKnownScrollY: number) => {
    if (!this.state.swapped && currentScrollY > this.props.scrollInHeight) {
      return true;
    } else if (
      this.state.swapped &&
      currentScrollY <= this.props.scrollInHeight
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const classes = classnames('fixed-top', { swapped: this.state.swapped });
    const initialNavBarClone = React.cloneElement(this.props.initialNavbar, {
      className: classnames(
        this.props.initialNavbar.props.className,
        'transition-style',
        'initial-nav'
      )
    });
    const swappedNavBarClone = React.cloneElement(this.props.swappedNavbar, {
      className: classnames(
        this.props.swappedNavbar.props.className,
        'transition-style',
        'swapped-nav'
      )
    });
    return (
      <div id="top-navs" className={classes}>
        {initialNavBarClone}
        {swappedNavBarClone}
      </div>
    );
  }
}
